import { handleQueryResolve } from '../utils';

export default function(name){
    return this.query(`
        SELECT 
            PatientID, LastName, FirstName 
        FROM 
            Fillware.dbo.Patient 
        WHERE
            FirstName LIKE @name
        ORDER BY 
            FirstName, LastName 
        ASC
    `,
    [{
        param: 'name',
        type: 'sql.VarChar(50)',
        value: '%' + name + '%'
    }])
    .then(handleQueryResolve);
}